export class ChangeDataGenerateExcelItem {
  materialNumber?: string;
  changeTypeId?: number;
  changeType?: string;
  plantOtherChangeType: string = '';
  oldData?: string;
  newData?: string;
  requestorNotes?: string;
  description?: string;
  qcCodes?: string;
  oem?: string;
  oemPartNumber?: string;
  vendor?: string;
}

export class MmsChangeDataDto{
  materialNumber?: string;
  oldData?: string;
  requestorNotes?: string;
  description?: string;
  qcCodes?: string;
  oem?: string;
  oemPartNumber?: string;
  vendor?: string;
}

export class BranchPlantDetails {
  branchPlantCode?: string;
  branchPlantCurrentValue?: string;
  status?: boolean;
}