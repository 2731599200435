import { Component, OnInit } from '@angular/core';
import { StockingLocation } from '../../../models/stockinglocation/stockinglocation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StockingLocationConstant } from 'src/app/core/constants/stocking-location.constant';
import { SarAllocationService } from 'src/app/features/sar-allocation/services/sar-allocation-service';
import { SelectDataDto } from 'src/app/core/models/select-data.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StockingLocationsService } from '../../../services/stocking-locations.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-manage-stocking-locations',
  templateUrl: './manage-stocking-locations.component.html',
  styleUrls: ['./manage-stocking-locations.component.scss']
})
export class ManageStockingLocationsComponent implements OnInit {

  stockingLocation: StockingLocation = new StockingLocation();  
  isSAR: boolean = false;
  assets: SelectDataDto[] = [];
  isCreate = false;
  public headerText: string = StockingLocationConstant.createText;

  stockingLocationForm = new FormGroup({
    branch: new FormControl(''),
    branchDescription: new FormControl(''),
    company: new FormControl('',  Validators.pattern('^[0-9]*$')),
    location: new FormControl(''),
    asset: new FormControl(''),
    assetDescription: new FormControl(''),
    assetFunction: new FormControl(''),
    assetGroup: new FormControl([] as { id: number, name: string }[]),
    isPQ: new FormControl(false),
    isNCR: new FormControl(false),
    isSAR: new FormControl(false),
    isStockingLocation: new FormControl(false),
  }); 

  constructor(
    private sarAllocationService: SarAllocationService,
    private stockingLocationService: StockingLocationsService,    
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute) { 
      let stateData: any = this.router.getCurrentNavigation()?.extras.state;
      this.isCreate = stateData['isCreate']
      
      if(!this.isCreate){
        this.headerText = StockingLocationConstant.updateText
        this.stockingLocation = stateData['data']        
        this.loadData(this.stockingLocation)
      }
    }

  ngOnInit() {
    this.sarAllocationService.getAssets().subscribe(data=>{
      this.assets = data
    })    
  }

  onCheckboxChange(value: any){
    this.isSAR = value.target.checked

    if(value.target.checked === false){
      this.stockingLocationForm.patchValue({
        isStockingLocation : false
      })
    }    
  }

  loadData(stockingLocation: StockingLocation){
    this.stockingLocationForm.patchValue({branch : stockingLocation.code})
    this.stockingLocationForm.patchValue({branchDescription : stockingLocation.description})
    this.stockingLocationForm.patchValue({company : stockingLocation.company})
    this.stockingLocationForm.patchValue({location : stockingLocation.location})
    this.stockingLocationForm.patchValue({asset : stockingLocation.asset})
    this.stockingLocationForm.patchValue({assetDescription : stockingLocation.assetDescription})
    this.stockingLocationForm.patchValue({assetFunction : stockingLocation.assetFunction})

    let tempAssets = stockingLocation.assets?.split(',').map(
      (name, index) => ({
        id: index + 1, 
        name: name.trim()
        }
      ));

    this.stockingLocationForm.patchValue({assetGroup : tempAssets || []})
    this.stockingLocationForm.patchValue({isPQ : stockingLocation.isPQ})
    this.stockingLocationForm.patchValue({isNCR : stockingLocation.isNCR})
    this.stockingLocationForm.patchValue({isSAR : stockingLocation.isSAR})
    this.isSAR = stockingLocation.isSAR;

    this.stockingLocationForm.patchValue({isStockingLocation : stockingLocation.isStockingLocation})
  }

  cancel() {
    this.router.navigate([StockingLocationConstant.StockingLocationsPath], { relativeTo: this.route.parent });
  }

  save(){
    let tempAssets: { id: number, name: string }[] ;

    this.stockingLocation.code = this.stockingLocationForm.get('branch')?.value || undefined;
    this.stockingLocation.description = this.stockingLocationForm.get('branchDescription')?.value || undefined;
    this.stockingLocation.company = this.stockingLocationForm.get('company')?.value || undefined;
    this.stockingLocation.location = this.stockingLocationForm.get('location')?.value || undefined;
    this.stockingLocation.asset = this.stockingLocationForm.get('asset')?.value || undefined;
    this.stockingLocation.assetDescription = this.stockingLocationForm.get('assetDescription')?.value || undefined;
    this.stockingLocation.assetFunction = this.stockingLocationForm.get('assetFunction')?.value || undefined;
    tempAssets = this.stockingLocationForm.get('assetGroup')?.value! || [];
    this.stockingLocation.isPQ = this.stockingLocationForm.get('isPQ')?.value!;
    this.stockingLocation.isNCR = this.stockingLocationForm.get('isNCR')?.value!;
    this.stockingLocation.isSAR = this.stockingLocationForm.get('isSAR')?.value!;
    this.stockingLocation.isStockingLocation = this.stockingLocationForm.get('isStockingLocation')?.value!; 
    this.stockingLocation.assets = tempAssets.map(x=>x.name).join(", ");
    
    if(this.isCreate){
      this.stockingLocation.createdBy = this.commonService.getUserEmailId();
    }
    
    this.stockingLocation.modifiedBy = this.commonService.getUserEmailId();
    this.stockingLocation.isActive = true;
    
    if(this.isCreate){
      this.stockingLocationService.addStockingLocation(this.stockingLocation).subscribe({
        next: (response: StockingLocation )=> {
          this.router.navigate([StockingLocationConstant.StockingLocationsPath], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('Stocking Location has been added successfully', 'success', 'right')
        },
          error: err => {
            this.commonService.showNotificationMessage(err.message, 'error', 'center')
          }})      
    }
    else{
      this.stockingLocationService.updateStockingLocation(this.stockingLocation).subscribe({
        next: (response: StockingLocation )=> {
          this.router.navigate([StockingLocationConstant.StockingLocationsPath], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('Stocking Location has been update successfully', 'success', 'right')
        },
          error: err => {
            this.commonService.showNotificationMessage(err.message, 'error', 'center')
          }})      
    }      
  }
}
