<div class="sar-contentBox">
  <div class="content-top d-flex j-c-s">
    <h1>
      <a class="c-left" (click)="navBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
      <span class="in-active">
        Stock Authorization Request
        <span>{{requestNumber}}</span>
      </span>
      <span class="active">
        <i class="fa fa-fw fa-angle-right"></i>
        <span> Generate Excel</span>
      </span>
    </h1>
    <div>
      <button kendoButton class="border-button ml-5 f-size-150" (click)="navBack()"> Cancel </button>
      <button kendoButton class="border-button ml-5 f-size-210" (click)="downloadExcelTemplate()">
        Download Excel
        Template
      </button>
    </div>
  </div>
  <!-- Material Numbers to validate -->
  <section class="w-75 d-flex flex">
    <div class="w-75 mx-5">
      <div>
        <kendo-label text="Material Number"></kendo-label>
      </div>
      <kendo-textarea resizable="vertical" rounded="small" [style.width.px]="500" [rows]="10"
                      [(ngModel)]="enteredmaterialNumbers">
      </kendo-textarea>
      <button kendoButton class="primary-button f-size-50 mx-5" [disabled]="!enteredmaterialNumbers" (click)="onValidate()">
        Validate
      </button> 
    </div>
  </section>
  <br />

  <section class="w-75 d-flex flex">
    <div class="w-75 mx-5 form-container">
      <kendo-formfield showHints="always">
        <kendo-label text="Select validated JDE numbers">
          <span style="color:red"> * </span>
          <i kendoTooltip title="Use CTRL + A to select all items" class="icon-max-height k-icon k-i-information"></i>
        </kendo-label>
          <div>
            <kendo-multiselect 
                [data]="validMaterialNumbers"
                [value]="selectedMaterialNumbers"
                [(ngModel)]="selectedMaterialNumbers"
                >
            </kendo-multiselect>
          </div>
      </kendo-formfield>
    
      <kendo-formfield showHints="always">
        <kendo-label text="Choose a Type of Change"><span style="color:red"> *</span></kendo-label>
          <div>
            <kendo-multiselect
            [data]="typesOfChange"
            textField="label"
            valueField="id"
            [(ngModel)]="selectedTypesOfChange"
            [value]="selectedTypesOfChange"
            (valueChange)="onValueChange($event)"
          ></kendo-multiselect>
          </div>
      </kendo-formfield>

      <kendo-formfield showHints="always">
        <kendo-label text="Choose a Branch Plant"></kendo-label>
          <div>
            <kendo-multiselect
            [data]="branchPlants"
            [disabled]="isBranchPlantDisabled"
            [value]="selectedBranchPlants"
            [(ngModel)]="selectedBranchPlants"
          ></kendo-multiselect>
          </div>  
      </kendo-formfield>

      <kendo-button class="primary-button f-size-50"
              [disabled]="isButtonTypeOfChangeDisabled"
              (click)="onAddTypeOfChange()">
        Add type of change
      </kendo-button>
    </div>
  </section>
  
  <section>
    <div class="w-75 mt-15 mx-5 form-container">
      <kendo-grid [data]="typeOfChangeGridData" [height]="400">
        <kendo-grid-column field="typeOfDataChange" title="Type of Change" [headerStyle]="{'width': '5%'}"
                           [style]="{'width': '5%'}">
        </kendo-grid-column>

        <kendo-grid-column field="totalItems" title="Total Items" [headerStyle]="{'width': '5%'}"
                           [style]="{'width': '5%'}">
        </kendo-grid-column>
        <kendo-grid-column field="branchPlants" title="Branch Plant" [headerStyle]="{'width': '5%'}"
                           [style]="{'width': '5%'}">
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </section>

  <div class="mt-10 mx-5  w-50" *ngIf="isShowTypeOfChangeDataList" [style.width.px]="500">
    <kendo-label text="Type of Data Change"><span style="color:red"> *</span></kendo-label><br>
    <kendo-multiselect [filterable]="true" #multiselectChangeType id="multiselectChangeType"
                       [data]="matNumberchangeTypes" textField="label" valueField="id" [(ngModel)]="selectedValues"
                       (removeTag)="removeChangeType($event)" (valueChange)="selectChangeType($event)" (open)="onOpenChangeType($event)"
                       (close)="onCloseChangeType($event)" (click)="toggleChangeType()" class="mt-5">
      <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <span class="autoCompleteItem">
          <button class="autoCompleteItem">
            {{ dataItem.label }}
          </button>
        </span>
      </ng-template>
    </kendo-multiselect>
  </div>
  <!-- Plant -->
  <section class="w-75 d-flex flex" [style.width.px]="510" *ngIf="isShowPlantsDropdown">
    <div class="w-75 mx-5">
        <kendo-label text="Choose a Branch Plant "><span style="color:red"> *</span></kendo-label>
        <kendo-multiselect class="dropdown" 
            [data]="branchPlants" 
            [filterable]="true" 
            placeholder="" 
            [(ngModel)]="selectedBranchPlants"
            [value]="selectedBranchPlants">
        </kendo-multiselect>  
    </div>
  </section>
  <!-- Other -->
  <section class="w-75 d-flex flex" [style.width.px]="510" *ngIf="isShowOther">
    <div class="w-75 mx-5">
      <br>
      <kendo-label text="Other"></kendo-label>
      <kendo-textarea [rows]="5" resizable="both" #otherTextArea [(ngModel)]="otherValue">
      </kendo-textarea>
    </div>
  </section>
  <!-- Change Data Details -->
  <br />

  <br />
  <div class="note w-1200" *ngIf="note.length > 0">
    Kindly note that if the selected data change already exists for the selected material numbers, they are not
    displayed in the below table. For more information, click
    <i class="fa fa-info-circle mr-10" aria-hidden="true" (click)="showAndHideNote()"></i>
  </div>
  <div *ngIf="isNoteVisible && note.length > 0">
    <div *ngFor="let nt of note">
      <kendo-grid [data]="[nt]" [hideHeader]="true" scrollable="none" class="notes">
        <kendo-grid-column>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{dataItem}}</span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <br />
  <section *ngIf="excelFileData.length">
    <br>
    <br>
    <kendo-grid [data]="excelFileData" tyle="height:auto" #myGrid="kendoGrid">
      <kendo-grid-column field="materialNumber" title="Material Number" [headerStyle]="{'width': '5%'}"
                         [style]="{'width': '5%'}">
      </kendo-grid-column>
      <kendo-grid-column field="changeType" title="Change Type" [headerStyle]="{'width': '5%'}"
                         [style]="{'width': '5%'}">
      </kendo-grid-column>
      <kendo-grid-column field="plantOtherChangeType" title="Plant/Other types" [headerStyle]="{'width': '5%'}"
                         [style]="{'width': '5%'}">
      </kendo-grid-column>
      <kendo-grid-column field="oldData" title="Old Value" [headerStyle]="{'width': '5%'}" [style]="{'width': '5%'}">
      </kendo-grid-column>
      <kendo-grid-column title="Delete" [headerStyle]="{'width': '5%'}" [style]="{'width': '5%'}">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div (click)="removeRow(dataItem)" class="icons k-text-right k-icon k-i-delete"></div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>
</div>
