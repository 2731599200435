import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Observable } from 'rxjs';
import { ChangeDataConst } from 'src/app/core/constants/change-data.const';
import { ChangeTypeDto } from '../models/change-sar/change-type.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ChangeDataActionResponseDto } from '../models/change-sar/change-data-action-response.model';
import { requestIssueConst } from 'src/app/core/constants/request-Issue.const';
import { ChangeDataDto, ChangeTypeDetailsDto } from '../models/change-sar/change-data-new-item-detail.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeDataService {
  buName: string = '';

  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService, private commonService: CommonService) {
    this.buName = this.commonService.getUserBUName();
  }

  getChangeDataDetail(requestId?: number, requestIssueId?: number): Observable<ChangeDataActionResponseDto> {
    return this.httpUtilityService.getAll<ChangeDataActionResponseDto>(requestIssueConst.apiName + requestIssueConst.getChangeDataActionDetails + `/${requestId}&${requestIssueId}`);
  }

  getChangeTypes(): Observable<ChangeTypeDto[]> {
    return this.httpUtilityService.getAll<ChangeTypeDto[]>(ChangeDataConst.ApiName + ChangeDataConst.getChangeTypes);
  }

  getChangeDataInfo(materialNumber: string): Observable<ChangeTypeDetailsDto[]> {
    return this.httpUtilityService.getAll<ChangeTypeDetailsDto[]>
      (ChangeDataConst.ApiName + ChangeDataConst.getChangeDataInfo + `/${materialNumber}/${this.buName}`);
  }

  getChangeDataInfoForAbu(materialNumber: string, isAbu: boolean, assetName: string, changeType: string, changeTypeId: number): Observable<ChangeTypeDetailsDto[]> {
    return this.httpUtilityService.getAll<ChangeTypeDetailsDto[]>
      (ChangeDataConst.ApiName + ChangeDataConst.getChangeDataInfoForAbu + `/${materialNumber}/${this.buName}/${isAbu}/${assetName}/${changeType}/${changeTypeId}`);
  }

  createChangeDataAction(data: ChangeDataDto) {
    return this.httpUtilityService.post<ChangeDataDto>(ChangeDataConst.ApiName + ChangeDataConst.createChangeDataAction, data);
  }

  updateChangeDataAction(data: ChangeDataDto) {
    return this.httpUtilityService.put<ChangeDataDto>(ChangeDataConst.ApiName + ChangeDataConst.updateChangeDataAction, data);
  }

  getChangeDataAction(materialNumber: string, requestIssueId: number): Observable<ChangeDataDto> {
    return this.httpUtilityService.getAll<ChangeDataDto>(ChangeDataConst.ApiName + ChangeDataConst.getChangeDataAction
      + `/${materialNumber}&${requestIssueId}&${this.buName}`);
  }
  importChangeDataAction(changeData: ChangeDataDto[]) {
    return this.httpUtilityService.post(ChangeDataConst.ApiName + ChangeDataConst.importChangeDataAction, changeData);
  }
  getBranchPlantDetail(branchDto: any) {
    return this.httpUtilityService.post<any>(ChangeDataConst.ApiName + ChangeDataConst.getBranachPlants, branchDto);
  }
  getMatNumChangeTypeDetails(matNumChangeTypeDto: any) {
    return this.httpUtilityService.post<any>(ChangeDataConst.ApiName + ChangeDataConst.getMatNumChangeTypeDetails, matNumChangeTypeDto);
  }
}
