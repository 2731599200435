import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDataConst, ChangeDataEnum } from 'src/app/core/constants/change-data.const';
import { ExtensionMRPUpdateConst } from 'src/app/core/constants/extensionMRPupdate.const';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ChangeDataService } from '../../../services/change-data.service';
import { ChangeTypeDto } from '../../../models/change-sar/change-type.model';
import { BranchPlant, ChangeDataDto, ChangeTypeDetailsDto, ChangeTypeValueDto } from '../../../models/change-sar/change-data-new-item-detail.model';
import { UnitOfMeasure } from 'src/app/features/setup/models/unitofmeasure/unitofmeasure.model';
import { MaterialGroupCode } from 'src/app/features/setup/models/materialgroupcode/materialgroupcode.model';
import { combineLatest, forkJoin, of, switchMap } from 'rxjs';
import { UnitOfMeasureService } from 'src/app/features/setup/services/unit-of-measure.service';
import { MaterialGroupCodeService } from 'src/app/features/setup/services/material-group-code.service';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { RequestLineService } from '../../../services/request-line.service';
import { ReAssignItemComponent } from '../../approval-management/re-assign-item/re-assign-item.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MaterialNumberJdeService } from '../../../services/material-number-jde.service';

@Component({
  selector: 'app-change-data-add-new-item',
  templateUrl: './change-data-add-new-item.component.html',
  styleUrls: ['./change-data-add-new-item.component.scss']
})
export class ChangeDataAddNewItemComponent implements OnInit {
  newActionInputParam: NewActionInputParam = {};
  buttonText = "Add";
  lineItem: string = '';
  requestLineId: number = 0;
  materialNumbers: number[] = [];
  selectedMaterial: string = '';
  prevSelectedMaterial: string = '';
  materialList: string[] = [];
  materialData: string[] = [];
  matValidError: boolean = false;
  maxCharacters: number = 2500;
  changeDataNewItemDetail!: ChangeDataDto;
  public selectedValues: ChangeTypeDto[] = [];
  public virtual: any = {
    itemHeight: 50,
  };
  @ViewChild("autocomplete", { static: false })
  public autocomplete!: AutoCompleteComponent;
  matError: boolean = false;
  changeTypes: ChangeTypeDto[] = [];
  disabledTypes: boolean = true;
  changedataInfo: ChangeTypeDetailsDto[] = [];
  changeDataCurrentitems: ChangeTypeDetailsDto[] = [];
  public readonly changeDataEnum: typeof ChangeDataEnum = ChangeDataEnum;
  uomData: UnitOfMeasure[] = [];
  materialGroupCodeData: MaterialGroupCode[] = [];
  requesterNotes: string = '';
  removingItem: any;
  isDataExist: boolean = false;
  exisitingData: any;
  editStatus: string = '';
  lineAssignment?: LineAssignmentDto = new LineAssignmentDto();
  buId: number | undefined;
  plantList: BranchPlant[] = [];
  materialNumValidationError: boolean = false;
  isABU: boolean = false;
  assetName: string = '';

  constructor(private router: Router,
    private commonService: CommonService,
    private materialInfoService: MaterialInfoService,
    private changeDataService: ChangeDataService,
    private unitOfMeasureService: UnitOfMeasureService,
    private materialGroupCodeService: MaterialGroupCodeService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private requestLineService: RequestLineService,
    private materialNumberJdeService: MaterialNumberJdeService) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    this.isABU = this.commonService.getUserBUName() === 'ABU';
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.exisitingData = stateData['exisitingData'];
      this.lineItem = stateData['lineItem'];
      this.requestLineId = stateData['requestLineId'];
      this.materialNumbers = stateData['materialNumbers'];
      this.selectedMaterial = stateData['materialNumber'];
      this.isDataExist = stateData['isDataExist'];
      this.editStatus = stateData['status'];
      this.buId = stateData['buId'];
      this.assetName = stateData['assetName'];
      sessionStorage.setItem('changeData', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('changeData')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.lineItem = sessionState.lineItem;
        this.isDataExist = sessionState.isDataExist;
        this.requestLineId = sessionState.requestLineId;
        this.materialNumbers = sessionState.materialNumbers;
        this.selectedMaterial = sessionState.materialNumber;
        this.exisitingData = sessionState.exisitingData;
        this.editStatus = sessionState.status;
        this.buId = sessionState.buId;
        this.assetName = sessionState.assetName;
      }
    }
  }

  ngOnInit(): void {
    if (this.commonService.isNullOrEmptyOrUndefined(this.editStatus)) {
      this.editStatus = 'Draft';
    }

    const uom = this.unitOfMeasureService.getUnitOfMeasure();
    const materialGroupCode = this.materialGroupCodeService.getMaterialGroupCode();
    combineLatest([uom, materialGroupCode]).subscribe(results => {
      this.uomData = this.addDisplayName(results[0]);
      this.materialGroupCodeData = this.addDisplayName(results[1]);
      if (!this.newActionInputParam.isNew) {
        this.getChangeDataInfo();
        this.disabledTypes = false;
        if (!this.newActionInputParam.isNew) {
          this.disabledTypes = true;
          this.buttonText = "Update"
        }
      }
    });
  }

  updateCharaters(item: any): number {
    if (item) {
      return item.length;
    }
    return 0;
  }

  getChangeDataAction() {
    this.changeDataService.getChangeDataAction(this.selectedMaterial, this.newActionInputParam.requestIssueId!).subscribe((result: ChangeDataDto) => {
      this.changeDataNewItemDetail = result;
      this.requesterNotes = result.requesterNotes!;
      let changeTypesEdit: ChangeTypeDto[] = []
      result.changeTypeDetails!.forEach(ele => {
        let changeTypeEdit: ChangeTypeDto = new ChangeTypeDto();
        changeTypeEdit.id = parseInt(ele.changeTypeId?.toString()!);
        changeTypeEdit.label = ele.changeType;
        changeTypeEdit.requiresBranchPlant = ele.changeTypeValues![0].isBranchPlantApplicable;
        changeTypeEdit.status = true;
        if (ele.changeTypeId === this.changeDataEnum.materialGroupCode) {
          ele.changeTypeValues![0].newData = this.materialGroupCodeData.find(x => x.code === ele.changeTypeValues![0].newData!)!;
        }
        if (ele.changeTypeId === this.changeDataEnum.unitOfMeasure) {
          ele.changeTypeValues![0].newData = this.uomData.find(x => x.code === ele.changeTypeValues![0].newData!)!;
        }
        if (ele.changeTypeId === this.changeDataEnum.leadTime) {
          ele.changeTypeValues?.forEach(changeType => {
            changeType.branchPlantOrOtherChangePlant = changeType.branchPlants!.find(x => x.branchPlantCode === changeType.branchPlantOrOtherChangePlant!)!;
            changeType.newData = parseFloat(changeType.newData);
          })
        }
        changeTypesEdit.push(changeTypeEdit);
      })
      this.selectedValues = changeTypesEdit;
      this.changeStatus(this.selectedValues);
      let existingdata = Object.assign([], this.changeDataNewItemDetail.changeTypeDetails);
      this.changeDataCurrentitems = existingdata;
    })
  }

  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputData: Array<string>) => {
      this.materialList = outputData;
      this.materialData = outputData;
    });
  }

  filter(value: any) {
    if (value.length >= 3) {
      this.materialNumberJdeService.getMaterialNumbers(value).pipe(
        switchMap(result => {
          return of(result);
        })
      ).subscribe(result => {
        this.materialData = result;
        this.materialList = result;
      });
    } else {
      this.autocomplete.toggle(false);
    }
  }

  materialChange(value: any) {
    let indexOfSelectedMaterial = this.materialData.findIndex(x => x === value);
    if (indexOfSelectedMaterial === -1) {
      this.disabledTypes = true;
      this.matValidError = true;
      this.materialNumValidationError = false;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    else {
      this.matValidError = false;
      this.materialNumValidationError = false;
      this.disabledTypes = false;
      this.getChangeTypes();
      if (value !== this.prevSelectedMaterial) {
        this.changeDataCurrentitems = [];
        this.selectedValues = [];
        this.selectedMaterial = value;
      }
      this.prevSelectedMaterial = value;
      if (!this.commonService.isNullOrEmptyOrUndefined(value)) {
        this.matError = false;
      }
    }
    if (!this.commonService.isNullOrEmptyOrUndefined(value)) {
      this.matError = false;
    }
  }

  isMaterialNumberValid(materialNumber: any) {
    let buName = this.commonService.getUserBUName();
    this.materialInfoService.isValidMaterialNumber<[]>(buName, materialNumber).subscribe((res: any) => {
      if (res) {
        this.matValidError = false;
        this.materialNumValidationError = false;
        this.disabledTypes = false;
        if (materialNumber !== this.prevSelectedMaterial) {
          this.changeDataCurrentitems = [];
          this.selectedValues = [];
          this.getChangeTypes();
        }
        this.prevSelectedMaterial = materialNumber;
        if (!this.commonService.isNullOrEmptyOrUndefined(materialNumber)) {
          this.matError = false;
        }
      }
      else {
        this.disabledTypes = true;
        this.matValidError = false;
        this.materialNumValidationError = true;
        this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
        return false;
      }
    });
  }

  BranchPlantsRemoveDuplicates(table: BranchPlant[]) {
    const uniqueArray = table.reduce((acc: BranchPlant[], current: BranchPlant) => {
      if (acc.filter(x => (x.branchPlantCode === current.branchPlantCode)).length === 0)
        acc.push(current);
      return acc
    }, []);
    return uniqueArray;
  }

  getChangeTypes() {
    this.plantList = [];
    let getChangeTypesAPI = this.changeDataService.getChangeTypes();
    let getChangeDataInfoAPI = this.changeDataService.getChangeDataInfo(this.selectedMaterial);

    forkJoin([getChangeTypesAPI, getChangeDataInfoAPI]).subscribe(([getChangeTypesData, getChangeDataInfoData]) => {
      let exisitingMatData = this.exisitingData.filter((x: any) => x.materialNumber === this.selectedMaterial);
      let changeTypeDetailsDto: ChangeTypeDto[] = [];
      for (let data of getChangeTypesData) {
        if (data.label === 'Other') {
          changeTypeDetailsDto.push(data);
        }
        else if (data.label === 'Lead Time') {
          let changeTypeValues: ChangeTypeValueDto[] | undefined = getChangeDataInfoData.find((x: ChangeTypeDetailsDto) => (x.changeTypeId === this.changeDataEnum.leadTime))?.changeTypeValues;
          if (changeTypeValues && changeTypeValues.length > 0) {
            this.plantList = (changeTypeValues[0].branchPlants && changeTypeValues[0].branchPlants.length > 0) ? changeTypeValues[0].branchPlants : [];
            this.plantList = this.BranchPlantsRemoveDuplicates(this.plantList);
          }
          let existingLeadTimeItems = exisitingMatData.filter((x: any) => (x.changeType === data.label && x.status !== 'Cancelled'));
          if (existingLeadTimeItems.length > 0) {
            let existingPlants: string[] = existingLeadTimeItems.map((x: any) => { return x.plantOtherChangeType });
            existingPlants.forEach((existingPlant: string) => {
              this.plantList = this.plantList.filter((plant: BranchPlant) => plant.branchPlantCode !== existingPlant);
            })
            if (this.plantList.length > 0) {
              changeTypeDetailsDto.push(data);
            }
          }
          else {
            changeTypeDetailsDto.push(data);
          }
        }
        else {
          if (exisitingMatData.filter((x: any) => x.changeType === data.label && x.status !== 'Cancelled').length === 0) {
            changeTypeDetailsDto.push(data);
          }
        }
      }
      this.changeTypes = changeTypeDetailsDto;
      this.changedataInfo = getChangeDataInfoData;
      if (!this.newActionInputParam.isNew) {
        this.getChangeDataAction();
      }
    });
  }

  getChangeDataInfo() {
    this.changeDataService.getChangeDataInfo(this.selectedMaterial).subscribe((data: ChangeTypeDetailsDto[]) => {
      this.changedataInfo = data;
      if (!this.newActionInputParam.isNew) {
        this.getChangeDataAction();
      }
    });
  }

  removeItem(event: any) {
    this.changeTypes.forEach((element: any) => {
      if (event.dataItem.id === element.id) {
        element.status = false;
      }
    });
  }

  changeStatus(selectedValues: any) {
    this.changeTypes.forEach((element: any) => {
      if (selectedValues.findIndex((x: any) => x.id === element.id) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }
  getNewValueName(val: any) {
    if (!this.commonService.isNullOrEmptyOrUndefined(val) && typeof val === 'object') {
      return val.name;
    }
    return val;
  }

  changeTypeChange(event: any) {
    if (this.isABU) {
      if (event.length === 0) {
        this.changeDataCurrentitems = [];
        this.changeTypes.forEach((element: any) => {
          element.status = false;
        });
        this.removingItem = null;
        return false;
      }
      if (this.removingItem) {
        this.changeDataCurrentitems = this.changeDataCurrentitems.filter(x => x.changeTypeId !== this.removingItem.id);
        let index = this.changeTypes.findIndex(x => x.id === this.removingItem.id)
        this.changeTypes[index].status = false;
        this.removingItem = null;
        return false;
      }

      let changeTypeId = event[event.length - 1].id;
      let changeType = event[event.length - 1].label;

      this.changeDataService.getChangeDataInfoForAbu(this.selectedMaterial, this.isABU, this.assetName, changeType, changeTypeId)
        .subscribe((data: ChangeTypeDetailsDto[]) => {
          this.changedataInfo = data;
          if (!this.newActionInputParam.isNew) {
            this.getChangeDataAction();
          }

          this.changeDataCurrentitems.push(this.changedataInfo[0]);
        });
    }

    else {
      if (event.length === 0) {
        this.changeDataCurrentitems = [];
        this.changeTypes.forEach((element: any) => {
          element.status = false;
        });
        this.removingItem = null;
        return false;
      }
      if (this.removingItem) {
        this.changeDataCurrentitems = this.changeDataCurrentitems.filter(x => x.changeTypeId !== this.removingItem.id);
        let index = this.changeTypes.findIndex(x => x.id === this.removingItem.id)
        this.changeTypes[index].status = false;
        this.removingItem = null;
        return false;
      }
      this.changeStatus(event);
      let selectedtype = this.changedataInfo.find(f => f.changeTypeId === event[event.length - 1].id);

      if (selectedtype?.changeTypeId === this.changeDataEnum.other || selectedtype?.changeTypeId === this.changeDataEnum.leadTime) {
        selectedtype.changeTypeValues![0].branchPlantOrOtherChangePlant = null;
        selectedtype.changeTypeValues![0].isShowAdd = true;
        selectedtype.changeTypeValues![0].isShowDelete = false;
        let count = this.changeDataCurrentitems.filter(x => x.changeTypeId === selectedtype!.changeTypeId).length
        selectedtype!.order = count + 1;
      }
      if (selectedtype?.changeTypeId === this.changeDataEnum.leadTime) {
        selectedtype!.changeTypeValues![0].branchPlants = this.plantList;
      }
      else {
        selectedtype!.changeTypeValues![0].branchPlants = selectedtype!.changeTypeValues![0].branchPlants == null ? [] : selectedtype!.changeTypeValues![0].branchPlants
      }
      selectedtype!.requestId = selectedtype?.requestId;
      selectedtype!.changeTypeValues![0].newData = null;
      selectedtype!.changeTypeValues![0].status = '';
      this.changeDataCurrentitems.push(selectedtype!);
    }
  }

  removeChangeType(event: any) {
    this.removingItem = event.dataItem;
  }

  getLengthOfMultiRow(id: number, rowIndex: number, dataItem: ChangeTypeValueDto) {
    let i = 0;
    for (let changeData of this.changeDataCurrentitems) {
      if (changeData.changeTypeId !== id) {
        i++
      }
      else {
        break;
      }
    }
    let fData = this.changeDataCurrentitems.filter(x => x.changeTypeId === id);
    if (id === this.changeDataEnum.leadTime) {
      return ((fData.length === 1 ||
        (rowIndex - i) === fData.length - 1) && dataItem.branchPlants?.length !== fData.length);
    }
    return (fData.length === 1 ||
      (rowIndex - i) === fData.length - 1);
  }

  getLengthOfMultiRowDelete(id: number, rowIndex: number) {
    let fData = this.changeDataCurrentitems.filter(x => x.changeTypeId === id);
    return (fData.length !== 1);
  }

  removeRow(id: number, order: number, index: number) {
    this.changeDataCurrentitems.splice(index, 1);
  }

  addNew(changeTypeId: number) {
    this.changeDataCurrentitems.forEach(ele => {
      if (ele.changeTypeId === changeTypeId) {
        ele.changeTypeValues?.forEach(element => {
          element.isShowAdd = false;
          element.isShowDelete = true;
        })
      }
    })
    let newtype = Object.assign({}, this.changedataInfo.find(f => f.changeTypeId === changeTypeId)!);
    let changeTypeValues: ChangeTypeValueDto = new ChangeTypeValueDto();
    changeTypeValues.branchPlantOrOtherChangePlant = null;
    changeTypeValues.isBranchPlantApplicable = newtype.changeTypeValues![0].isBranchPlantApplicable;
    changeTypeValues.oldData = '';
    changeTypeValues.newData = null;
    changeTypeValues.isShowAdd = true;
    changeTypeValues.isShowDelete = true;
    changeTypeValues.branchPlants = newtype.changeTypeValues![0].branchPlants == null ? [] :
      newtype.changeTypeValues![0].branchPlants;
    newtype.changeTypeValues = [];
    newtype.changeTypeValues.push(changeTypeValues);
    if (newtype.changeTypeId === this.changeDataEnum.leadTime || newtype.changeTypeId === this.changeDataEnum.other) {
      let count = this.changeDataCurrentitems.filter(x => x.changeTypeId === newtype.changeTypeId).length
      newtype.order = count + 1;
    }
    this.changeDataCurrentitems.push(newtype);
    let currentData: ChangeTypeDetailsDto[] = [];
    for (let data of this.changeDataCurrentitems) {
      if (data.changeTypeId === this.changeDataEnum.other || data.changeTypeId === this.changeDataEnum.leadTime) {
        let currentValue = currentData.find(x => x.changeTypeId === data.changeTypeId)
        if (currentValue == null) {
          let filterredData = this.changeDataCurrentitems.filter(x => x.changeTypeId === data.changeTypeId)
          let count = filterredData.length
          newtype.order = count + 1;
          currentData.push(...filterredData)
        }
      }
      else {
        currentData.push(data);
      }
    }
    this.changeDataCurrentitems = currentData;
  }

  addDisplayName(data: UnitOfMeasure[] | MaterialGroupCode[]): any {
    if (data.length > 0) {
      data.forEach(el => el.displayName = `${el.code} : ${el.name}`)
      return data;
    }
    return [];
  }

  validate() {
    let hasError = false;
    if (this.newActionInputParam.isNew) {
      if (this.commonService.isNullOrEmptyOrUndefined(this.selectedMaterial) || this.selectedValues.length === 0) {
        return true;
      }

      if (this.isABU) {
        this.changeDataCurrentitems.forEach(data => {
          data.changeTypeValues?.forEach(ct => { 
            console.log('Loop');
            hasError = !this.hasValidChangeTypeScenarios(ct, data.changeType!);
          })
        });
        return hasError;
      }

      else {
        this.changeDataCurrentitems.forEach(data => {
          if (data.changeTypeId === this.changeDataEnum.other || data.changeTypeId === this.changeDataEnum.leadTime) {
            if (this.commonService.isNullOrEmptyOrUndefined(data.changeTypeValues![0].branchPlantOrOtherChangePlant)) {
              data.changeTypeValues![0].hasPlanterror = true;
              hasError = true;
            }
            else {
              data.changeTypeValues![0].hasPlanterror = false;
            }
          }
          if (this.commonService.isNullOrEmptyOrUndefined(data.changeTypeValues![0].newData)) {
            data.changeTypeValues![0].hasError = true;
            hasError = true;
          }
          else {
            data.changeTypeValues![0].hasError = false;
          }
          if (data.changeTypeId === this.changeDataEnum.leadTime && !data.changeTypeValues![0].hasPlanterror) {
            let duplicatedCount: number = 0;
            if (typeof data.changeTypeValues![0].branchPlantOrOtherChangePlant === 'string') {
              duplicatedCount = this.changeDataCurrentitems
                .filter(x => x.changeTypeId === data.changeTypeId && x.changeTypeValues![0].branchPlantOrOtherChangePlant.branchPlantCode
                  === data.changeTypeValues![0].branchPlantOrOtherChangePlant).length
            }
            else {
              duplicatedCount = this.changeDataCurrentitems
                .filter(x => x.changeTypeId === data.changeTypeId && x.changeTypeValues![0].branchPlantOrOtherChangePlant.branchPlantCode
                  === data.changeTypeValues![0].branchPlantOrOtherChangePlant.branchPlantCode).length
            }
            if (duplicatedCount > 1) {
              data.changeTypeValues![0].hasPlantDuplicateError = true;
              hasError = true;
            }
            else {
              data.changeTypeValues![0].hasPlantDuplicateError = false;
            }
          }
        })
        return hasError;
      }
    }

    else {
      if (this.isABU) {
        this.changeDataCurrentitems.forEach(data => {
          data.changeTypeValues?.forEach(ct => {
            console.log('Loop');
            hasError = !this.hasValidChangeTypeScenarios(ct, data.changeType!);
          })
        });
        return hasError;
      }
      else {
        this.changeDataCurrentitems.forEach(data => {
          data.changeTypeValues?.forEach(ct => {
            if (data.changeTypeId === this.changeDataEnum.other || data.changeTypeId === this.changeDataEnum.leadTime) {
              if (this.commonService.isNullOrEmptyOrUndefined(ct.branchPlantOrOtherChangePlant)) {
                ct.hasPlanterror = true;
                hasError = true;
              }
              else {
                ct.hasPlanterror = false;
              }
              if (this.commonService.isNullOrEmptyOrUndefined(ct.newData)) {
                ct.hasError = true;
                hasError = true;
              }
              else {
                ct.hasError = false;
              }
              if (data.changeTypeId === this.changeDataEnum.leadTime && !ct.hasPlanterror) {
                let duplicatedCount: number = 0;
                if (typeof data.changeTypeValues![0].branchPlantOrOtherChangePlant === 'string') {
                  duplicatedCount = data.changeTypeValues!
                    .filter(x => x.branchPlantOrOtherChangePlant.branchPlantCode
                      === ct.branchPlantOrOtherChangePlant).length;
                }
                else {
                  duplicatedCount = data.changeTypeValues!
                    .filter(x => x.branchPlantOrOtherChangePlant.branchPlantCode
                      === ct.branchPlantOrOtherChangePlant.branchPlantCode).length;
                }
                if (duplicatedCount > 1) {
                  ct.hasPlantDuplicateError = true;
                  hasError = true;
                }
                else {
                  ct.hasPlantDuplicateError = false;
                }
              }
            }
          })
          if (this.commonService.isNullOrEmptyOrUndefined(data.changeTypeValues![0].newData)) {
            data.changeTypeValues![0].hasError = true;
            hasError = true;
          }
          else {
            data.changeTypeValues![0].hasError = false;
          }
        })
        return hasError;
      }
    }
  }

  hasValidChangeTypeScenarios(item: ChangeTypeValueDto, changeType: string): boolean {
    switch (changeType.toLocaleLowerCase()) {
      case 'material group code':
      case 'extend branch plant':
      case 'unit of measure':
      case 'other':
        return this.isValidNewDataNotEmpty(item);
      case 'manufacturer part number':
      case 'vendor part number':
      case 'supplier number':
      case 'planner number':
      case 'quality code':
      case 'description':
      case 'manufacturer':
        return this.isOldDataNonEmpty(item) && this.isValidNewDataNotEmpty(item);
      case 'lead time':
        return this.isOldDataNonEmpty(item) && this.isValidNewDataNotEmptyNumeric(item);
      case 'stocking type':
        return this.isOldDataNonEmpty(item) && this.isValidNewDataNotEmpty(item) && this.isValidBranchPlants(item);
      default:
        throw new Error('Invalid Change Type');
    }
  }

  isValidNewDataNotEmpty(item: ChangeTypeValueDto): boolean {
    if (item.newData === undefined || item.newData === null || item.newData === '') {
      item.hasError = true;
      return false;
    }
    else {
      return true;
    }
  }

  isValidNewDataNotEmptyNumeric(item: ChangeTypeValueDto): boolean {
    if (!this.isValidNewDataNotEmpty(item) || isNaN(Number(item.newData!)) === true) {
      item.hasError = true;
      this.commonService.showNotificationMessage('Enter numeric non empty new data value', 'error', 'right')
      return false;
    }
    else {
      return true;
    }
  }

  isOldDataNonEmpty(item: ChangeTypeValueDto): boolean {
    if (item.oldData === undefined || item.oldData === null || item.oldData === '') {
      item.hasError = true;
      return false;
    }
    else {
      return true;
    }
  }

  isValidBranchPlants(item: ChangeTypeValueDto): boolean {
    if (item.branchPlants === undefined || item.branchPlants.length === 0) {
      item.hasError = true;
      return false;
    }
    else {
      return true;
    }
  }

  onLeadTimeNewDataChange(rowIndex: number, gridRowIndex: number) {
    let newData = this.changeDataCurrentitems[rowIndex].changeTypeValues![gridRowIndex].newData;
    if (newData < 0) {
      this.changeDataCurrentitems[rowIndex].changeTypeValues![gridRowIndex].newData = 0;
    }
  }
  
  getStyle(hasError: boolean, hasPlantDuplicateError?: boolean) {
    if (hasError || hasPlantDuplicateError) {
      return 'grid-input errorClass'
    }
    return 'grid-input';
  }

  openreAssignment() {
    let rejectedLineItems = [];
    for (let items of this.changeDataCurrentitems) {
      let line = 'Line ' + ' - ' + items.lineNumber;
      let i = 0;
      for (let item of items.changeTypeValues!) {
        if (item.status === 'Rejected') {
          if (items.changeTypeId === this.changeDataEnum.leadTime || items.changeTypeId === this.changeDataEnum.other) {
            line = 'Line ' + (items.lineNumber! + i) + ' - ' + items.changeType;
            let lineItem = { lineName: line, id: item.id }
            rejectedLineItems.push(lineItem);
          }
          else {
            line = 'Line ' + items.lineNumber! + ' - ' + items.changeType;
            let lineItem = { lineName: line, id: item.id }
            rejectedLineItems.push(lineItem);
          }
        }
        i++;
      }

    }
    const dialog: DialogRef = this.dialogService.open({
      content: ReAssignItemComponent
    });
    const inc = dialog.content.instance as ReAssignItemComponent;
    inc.requestIssueId = this.newActionInputParam.requestIssueId!;
    inc.groupType = 1;
    inc.isChangeData = true;
    inc.rejectedLineItems = rejectedLineItems;

    inc.listItems = []
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.actionType) || r.actionType === 'Close') {
        return false;
      }
      this.lineAssignment = r.lineItemDtail as LineAssignmentDto;
      this.lineAssignment.requestId = this.newActionInputParam.requestId;
      this.lineAssignment.requestIssueId = this.newActionInputParam.requestIssueId;
      this.lineAssignment.requestIssueTypeId = 2;
      this.lineAssignment.businessUnitId = this.buId;
      this.lineAssignment.state = 0;
      this.lineAssignment.isGeneralComment = false;
      this.lineAssignment.comment = '';
      this.lineAssignment.createdBy = this.commonService.getUserEmailId();
      this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
      this.lineAssignment.attachments = [];
      this.invokUpdate();
    });
  }
  AddAndUpdate() {
    if (this.validate()) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.errorMessage, 'error', 'right')
      return false;
    }
    if (this.editStatus === 'Rejected') {
      this.openreAssignment();
      return false;
    }

    let changeData: ChangeDataDto = new ChangeDataDto();
    if (this.newActionInputParam.isNew) {
      changeData.id = 0;
      changeData.createdBy = this.commonService.getUserEmailId();
      changeData.modifiedBy = this.commonService.getUserEmailId();
      changeData.lineNumber = 0;
      changeData.materialNumber = this.selectedMaterial;
      changeData.requestId = this.newActionInputParam.requestId!;
      changeData.requestIssueId = this.commonService.isNullOrEmptyOrUndefined(this.newActionInputParam.requestIssueId)
        ? 0 : this.newActionInputParam.requestIssueId;
      changeData.requesterNotes = this.requesterNotes;
      changeData.requestIssueTypeId = 2;
      changeData.changeTypeDetails = JSON.parse(JSON.stringify(this.changeDataCurrentitems)) as ChangeTypeDetailsDto[];;
      changeData.changeTypeDetails.forEach(x => {
        if (x?.changeTypeId === this.changeDataEnum.unitOfMeasure || x?.changeTypeId === this.changeDataEnum.materialGroupCode) {
          if (typeof x.changeTypeValues![0].newData !== 'string') {
            x.changeTypeValues![0].newData = x.changeTypeValues![0].newData.code;
            
          }
        }
        if (x?.changeTypeId === this.changeDataEnum.leadTime && !this.isABU) {
          x.changeTypeValues?.forEach(y => {
            if (typeof y.branchPlantOrOtherChangePlant !== 'string') {
              y.branchPlantOrOtherChangePlant = y.branchPlantOrOtherChangePlant.branchPlantCode;
            }
            y.newData = y.newData.toString();
          })
        }

        if (x?.changeTypeId === this.changeDataEnum.stockingType && this.isABU) {
          x.changeTypeValues?.forEach(y => {
            if (typeof y.branchPlantOrOtherChangePlant !== 'string') {
              y.branchPlantOrOtherChangePlant = y.branchPlantOrOtherChangePlant.branchPlantCode;
            }
            y.newData = y.newData.toString();
          })
        }

      })
      this.save(changeData);
    }
    else {
      this.invokUpdate();
    }


  }
  invokUpdate() {
    let changeData: ChangeDataDto = new ChangeDataDto();
    changeData.id = this.changeDataNewItemDetail.id;
    changeData.createdBy = this.commonService.getUserEmailId();
    changeData.modifiedBy = this.commonService.getUserEmailId();
    changeData.lineNumber = this.changeDataNewItemDetail.lineNumber;;
    changeData.materialNumber = this.selectedMaterial;
    changeData.requestId = this.changeDataNewItemDetail.requestId
    changeData.requestIssueId = this.changeDataNewItemDetail.requestIssueId;
    changeData.requesterNotes = this.requesterNotes;
    changeData.requestIssueTypeId = 2;
    changeData.changeTypeDetails = JSON.parse(JSON.stringify(this.changeDataCurrentitems)) as ChangeTypeDetailsDto[];;
    changeData.changeTypeDetails.forEach(x => {
      if (x?.changeTypeId === this.changeDataEnum.unitOfMeasure || x?.changeTypeId === this.changeDataEnum.materialGroupCode) {
        if (typeof x.changeTypeValues![0].newData !== 'string') {
          x.changeTypeValues![0].newData = x.changeTypeValues![0].newData.code;
        }
      }
      if (x?.changeTypeId === this.changeDataEnum.leadTime) {
        x.changeTypeValues?.forEach(y => {
          if (typeof y.branchPlantOrOtherChangePlant !== 'string') {
            y.branchPlantOrOtherChangePlant = y.branchPlantOrOtherChangePlant.branchPlantCode;
          }
          y.newData = y.newData.toString();
        })
      }
    })
    this.update(changeData);

  }
  save(changeData: ChangeDataDto) {
    this.changeDataService.createChangeDataAction(changeData).subscribe(res => {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.successMessage, 'success', 'right')
      this.router.navigate(['./' + 'newaction'], {
        state: {
          isNew: false, 
          requestId: res.requestId,
          requestIssueId: res.requestIssueId,
          changeType: 'Change Data',
          requestNumber: this.newActionInputParam.requestNumber,
          actionNumber: this.newActionInputParam.actionNumber,
        }, relativeTo: this.route.parent
      });
    })
  }


  update(changeData: ChangeDataDto) {
    this.changeDataService.updateChangeDataAction(changeData).subscribe(res => {
      if (this.editStatus === 'Rejected') {
        this.saveAndAssign();
        return false;
      }
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.successMessage, 'success', 'right')
      this.router.navigate(['./' + 'newaction'], {
        state: {
          isNew: false, requestId: res.requestId,
          requestIssueId: res.requestIssueId, changeType: 'Change Data', requestNumber: this.newActionInputParam.requestNumber,
          actionNumber: this.newActionInputParam.actionNumber
        }, relativeTo: this.route.parent
      });
    })
  }


  saveAndAssign() {
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!) && !this.lineAssignment?.isMixedValues) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
    }
    else {
      let userId: any = this.lineAssignment!.assignedUserId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedUserId!.toString())) {
        userId = null;
      }
      let groupId: any = this.lineAssignment!.assignedGroupId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!)) {
        groupId = null;
      }
      const formData = new FormData();
      formData.append('requestId', this.lineAssignment!.requestId!.toString());
      formData.append('requestIssueId', this.lineAssignment!.requestIssueId!.toString());
      formData.append('requestIssueTypeId', this.lineAssignment!.requestIssueTypeId!.toString());
      formData.append('businessUnitId', this.lineAssignment!.businessUnitId!.toString());
      formData.append('assignedGroupId', groupId);
      formData.append('assignedUserId', userId);
      formData.append('state', this.lineAssignment!.state!.toString());
      formData.append('comment', this.lineAssignment!.comment!);
      formData.append('isGeneralComment', this.lineAssignment!.isGeneralComment!.toString());
      formData.append('createdBy', this.lineAssignment!.createdBy!);
      formData.append('modifiedBy', this.lineAssignment!.modifiedBy!);
      for (let attachment of this.lineAssignment!.attachments!) {
        formData.append('attachments', attachment);
      }
      for (let id of this.lineAssignment!.requestLineIds!) {
        formData.append('requestLineIds', id.toString());
      }
      this.requestLineService.updateLineAssignmentForRejected(formData).subscribe(response => {
        this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.updateSuccessMessage, 'success', 'right')
        this.router.navigate(['./' + 'newaction'], {
          state: {
            isNew: false, requestId: this.lineAssignment!.requestId!,
            requestIssueId: this.lineAssignment!.requestIssueId!, changeType: 'Change Data',
            requestNumber: this.newActionInputParam.requestNumber,
            actionNumber: this.newActionInputParam.actionNumber,
          }, relativeTo: this.route.parent
        });
      })
    }
  }

  navToBack() {
    if (this.isDataExist) {
      this.newActionInputParam.isNew = false;
    }
    this.router.navigate(['./' + 'newaction'], {
      state: {
        isNew: this.newActionInputParam.isNew, requestId: this.newActionInputParam.requestId,
        requestIssueId: this.newActionInputParam.requestIssueId, changeType: 'Change Data',
        requestNumber: this.newActionInputParam.requestNumber,
        actionNumber: this.newActionInputParam.actionNumber,
        assetName: this.assetName
      }, relativeTo: this.route.parent
    });
  }

  newDataChange(event: any, rowIndex: number, gridRowIndex: number, type: string) {
    let newValue: any = '';
    if (!this.commonService.isNullOrEmptyOrUndefined(event)) {
      newValue = type === 'uom' ? this.uomData.find((x: any) => x.id === event.id)! :
        this.materialGroupCodeData.find((x: any) => x.id === event.id)!;
    }
    this.changeDataCurrentitems![rowIndex].changeTypeValues![gridRowIndex].newData = newValue;
  }

  plantChange(event: any, rowIndex: number, gridRowIndex: number, branchPlants: BranchPlant[]) {
    let plantName = this.commonService.isNullOrEmptyOrUndefined(event?.branchPlantCode) ? event :
      event?.branchPlantCode
    let value: any = branchPlants.find(x => x.branchPlantCode === plantName);

    this.changeDataCurrentitems![rowIndex].changeTypeValues![gridRowIndex].branchPlantOrOtherChangePlant = value;
  }

}
