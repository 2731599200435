<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navToBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request</span>
            <span class="in-active">
                {{newActionInputParam.requestNumber}}</span>
            <span class="active" *ngIf="newActionInputParam.isNew">
                <i class="fa fa-fw fa-angle-right"></i> New
                Item</span>

            <span class="active" *ngIf="!newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>
                Item {{lineItem}}
            </span>
        </h1>
        <div>
            <button kendoButton class="border-button ml-20 f-size-150" (click)="navToBack()"> Cancel </button>
            <button kendoButton *ngIf="newActionInputParam && newActionInputParam.isNew === true"
                class="primary-button ml-20 f-size-150" (click)="addNewItem()"> Add </button>

            <button kendoButton *ngIf="newActionInputParam && newActionInputParam.isNew === false"
                class="primary-button ml-20 f-size-150" (click)="update()"> Update </button>
        </div>
    </div>
    <section>
      <div class="mt-10 w-50">
        <kendo-label text="Material Number"></kendo-label><span class="mandatory">*</span> <br>
        <kendo-autocomplete [data]="materialData" [disabled]="this.newActionInputParam.isNew===false"
                            class="autocomplete-box mt-5" [virtual]="virtual" [filterable]="true" (filterChange)="filter($event)"
                            (valueChange)="materialChange($event)" [(ngModel)]="selectedMaterial"
                            placeholder="Enter atleast 5 characters">
        </kendo-autocomplete>
        <span *ngIf="matError" class="noValue"> Material number field cannot be empty</span>
        <span *ngIf="matValidError" class="noValue"> Please select valid material number</span>
        <span *ngIf="materialNumValidationError" class="noValue"> Selected Material number does not belongs to current BU</span>
      </div>
        <div *ngIf="selectedMaterial">
            <div class="mt-10">
                <fieldset>
                    <legend for="">Item Details</legend>
                    <kendo-label text="Description">
                    </kendo-label>
                    <br />
                    {{description}}
                    <br />
                    <br />
                    <div *ngIf="selectedDeactivatedPlants.length > 0" class="warning">
                        <i class="fa fa-info-circle mr-10" aria-hidden="true"></i><b>Plant
                            {{selectedDeactivatedPlants[0].plantName}} </b> is already flagged
                        for deletion in SAP
                    </div>
                    <br *ngIf="selectedDeactivatedSLs.length > 0" />
                    <div *ngIf="selectedDeactivatedSLs.length > 0" class="warning">
                        <i class="fa fa-info-circle mr-10" aria-hidden="true"></i><b>Storage Location
                            {{selectedDeactivatedSLs[0].slName}} </b> is
                            already flagged
                        for deletion in SAP
                    </div>
                    <div *ngFor="let item of requestItems; let rowIndex = index">
                        <div class="remove-hover">
                            <kendo-grid [data]="gridData" [resizable]="true" [hideHeader]="rowIndex !== 0">
                                <kendo-grid-column title="Plant">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-dropdownlist [valuePrimitive]="true" [virtual]="virtual"
                                            [data]="plantList"
                                            (valueChange)="plantChange(item!.plantName,rowIndex);
                                            getUnitprice(item!.plantName!,rowIndex);
                                            getExistingROPMinAndMaxValue(item!.plantName!,item!.storageLocatinName!,rowIndex)"
                                            [(ngModel)]="item.plantName" [defaultItem]="defaultPlantItem"
                                            class="dropwdown-box" textField="plantName" valueField="plantName">
                                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                <div class="template">
                                                    <p class="word-wrap">{{ dataItem.plantName }} 
                                                    <br />
                                                    <small> -{{dataItem.plantDiscription}}</small></p></div>
                                            </ng-template>
                                        </kendo-dropdownlist>

                                        <div class="d-flex a-center">
                                            <input type="checkbox" [(ngModel)]="item.isPlantForDeletion" id="checkbox-{{rowIndex}}"
                                                [checked]="item.isPlantForDeletion" class="checkbox mr-5"
                                                [disabled]="isPlantDisabled(item.plantName!)"
                                                (change)="onPlantForDeletionFlagChange(rowIndex)" />
                                            <kendo-label text="Flag For Deletion"></kendo-label>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Unit Price">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        {{item.unitPrice}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Special Procurement Key">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-dropdownlist [disabled]="buName!=='SJVBU' || item.isPlantForDeletion"
                                            class="grid-input" [defaultItem]="defaultSPKItem" [(ngModel)]="item.spkId"
                                            (valueChange)="SPKChange($event,rowIndex)" [data]="SPKList" textField="name"
                                            valueField="id" [valuePrimitive]="true"></kendo-dropdownlist>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column *ngIf="!isABU" title="Storage Location">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-dropdownlist [(ngModel)]="item.storageLocatinName" class="grid-input"
                                            kendoGridFocusable textField="storageLocation"
                                            valueField="storageLocation" [defaultItem]="defaultSLItem"
                                            [data]="tempArray[rowIndex]" [valuePrimitive]="true"
                                            (valueChange)="SLChange($event,rowIndex);
                                            getExistingROPMinAndMaxValue(item!.plantName!,item!.storageLocatinName!,rowIndex)"
                                            [disabled]="item.isPlantForDeletion"></kendo-dropdownlist>
                                        <div class="d-flex">
                                            <input type="checkbox" [(ngModel)]="item.isStorageLocationForDeletion"
                                                [checked]="item.isStorageLocationForDeletion"
                                                [disabled]="item.isPlantForDeletion || isSLDisabled(item.storageLocatinName!)"
                                                (change)="onStorageLocationForDeletionFlagChange(rowIndex)"
                                                class="checkbox mr-5" />
                                            <kendo-label text="Flag For Deletion"></kendo-label>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="ROP(Min)">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-numerictextbox [(ngModel)]="item.minimum" 
                                            [min] ="0" (blur)="item.minimum = updateValue(item.minimum)"
                                            [disabled]="item.isPlantForDeletion || item.isStorageLocationForDeletion"
                                            class="grid-input" [spinners]="false"></kendo-numerictextbox>
                                        <div>
                                            <kendo-label text="Existing ROP (Min)"></kendo-label> <br>
                                            <small>{{item.existingMinimum?item.existingMinimum:0}}</small>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column>
                                    <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                        let-columnIndex="columnIndex">
                                        Max
                                        <span kendoTooltip tooltipClass="tooltip-design" id="info"
                                            title="Setting the MIN = 0 and MAX = 1 will mean when we use one, we will go and order another.
                                            A setting of MIN = 1 and MAX = 4 will mean when our stock level falls to 0, we will order 4 more to restore our max stock level."
                                            class="fa fa-question-circle primary-color title-tool"></span>

                                    </ng-template>
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-numerictextbox [(ngModel)]="item.maximum"
                                            [min] ="0" (blur)="item.maximum = updateValue(item.maximum)"
                                            [disabled]="item.isPlantForDeletion || item.isStorageLocationForDeletion"
                                            class="grid-input" [spinners]="false"></kendo-numerictextbox>
                                        <div>
                                            <kendo-label text="Existing Max"></kendo-label> <br>
                                            <small>{{item.existingMaximum?item.existingMaximum:0}}</small>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column>
                                    <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                        let-columnIndex="columnIndex">
                                        <span>Estimated Inventory Value
                                        <span kendoTooltip tooltipClass="tooltip-design" id="info1"
                                            title="Maximum x Quoted Price"
                                            class="fa fa-question-circle primary-color title-tool"></span></span>
                                    </ng-template>
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <small>USD
                                            ${{calculateInventeryValue(item!.unitPrice!,item!.maximum!,rowIndex)}}</small>
                                        <span class="hide">{{calculateTotalValue()}}</span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Bin">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-textbox [(ngModel)]="item.bin" class="grid-input"
                                            [disabled]="item.isPlantForDeletion">
                                        </kendo-textbox>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column *ngIf="!isABU" title="Valuation Type">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <kendo-dropdownlist [(ngModel)]="item.valuationTypeId" class="grid-input"
                                            kendoGridFocusable (valueChange)="vauluationChange($event,rowIndex)"
                                            textField="name" [defaultItem]="defaultVTItem" valueField="id"
                                            [valuePrimitive]="true" [data]="valuationTypeData"
                                            [disabled]="item.isPlantForDeletion"></kendo-dropdownlist>
                                        <div class="d-flex">
                                            <input type="checkbox" [(ngModel)]="item.isValuationTypeForDeletion"
                                                [checked]="item.isValuationTypeForDeletion"
                                                [disabled]="item.isPlantForDeletion" class="checkbox mr-5" />
                                            <kendo-label text="Flag For Deletion"></kendo-label>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="">
                                    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                        <div class="icons">
                                            <div *ngIf="item.plantName && rowIndex === (requestItems.length-1)"
                                                (click)="addNewRow()" class="k-icon k-i-plus">
                                            </div>
                                            <div *ngIf="requestItems.length > 1" (click)="removeRow(rowIndex)"
                                                class="k-icon k-i-delete"></div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>

                            </kendo-grid>
                            <div>
                               <span *ngIf="errorList[rowIndex]?.isPlantEmptyError === true" class="noValue">
                                Plant
                                details
                                cannot be empty
                              </span>
                              <br />
                              <span *ngIf="errorList[rowIndex]?.isDuplicateError === true" class="noValue">
                                Duplicate
                                Plant and
                                Storage location
                                found
                              </span>
                              <br />
                              <span *ngIf="errorList[rowIndex]?.isMinMaxError === true" class="noValue">
                                Max stock
                                level
                                must be greater than or
                                equal to the ROP (Min)
                              </span>                              
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="mt-10 w-50 d-flex">
                <div class="mr-20">
                    <kendo-label text="Total Value (USD)"></kendo-label> <br>
                    <small>{{totalValue}}</small>
                </div>
                <div>
                    <kendo-label text="Change In Value (USD)"></kendo-label> <br>
                    <small>
                        {{changeInValue}}
                    </small>
                </div>
            </div>
            <div class="mt-10 w-50">
                <kendo-label text="Requester Notes"></kendo-label> <br>
                <kendo-textarea class="mt-5" [(ngModel)]="requesterNotes" [rows]="4" [cols]="100"
                    resizable="both"></kendo-textarea>
            </div>
        </div>
    </section>
</div>
