import { StorageLocationDetailsDto } from "./storage-location-detail.model";

export class PlantDetailsDto {
    plantName!: string;
    plantDiscription!: string;
    storageLocationDetails!: StorageLocationDetailsDto[];
    displayName?: string;

    constructor(plantName: string, plantDiscription: string, storageLocationDetails: StorageLocationDetailsDto[], displayName?: string) {
        this.plantName = plantName;
        this.plantDiscription = plantDiscription;
        this.storageLocationDetails = storageLocationDetails;
        this.displayName = displayName;
    }
}


