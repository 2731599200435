export class RequestLineQACode {
    id?: number | undefined;
    requestLineId!: number | undefined;
    qaCodeId!: number;
    createdBy?: string | undefined;
    modifiedBy?: string | undefined;
    operation?: number | undefined;

}

export interface ApprovalQACodes {
    [key: number]: RequestLineQACode[];
}
