<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request
                <span>{{newActionInputParam.requestNumber}}</span>
            </span>
            <span class="active"><i class="fa fa-fw fa-angle-right"></i>
                Action
                <span> {{newActionInputParam.actionNumber}}</span>
            </span>
            <span *ngIf="actionName !== 'summary'" class="active">
                <i class="fa fa-fw fa-angle-right"></i>
                View Action
            </span>
        </h1>
    </div>
    <div *ngIf="newActionInputParam.changeType==='Add New Item'">
        <app-add-new-item-approval [requiredParams]="newActionInputParam" (saActionEvent)="saApprovalAction($event)"
            (mmdActionEvent)="mmdApprovalAction($event)" (saBulkActionEvent)="saBulkApprovalAction($event)"
            (mmdBulkActionEvent)="mmdBulkApprovalAction($event)" [dummyInput]="dummyInput"
            [currentActionName]="actionName" [isABU]="isABU"
            *ngIf="newActionInputParam.changeType==='Add New Item'"></app-add-new-item-approval>
    </div>
    <div *ngIf="newActionInputParam.changeType==='Change Data'">
        <app-change-data-approval [requiredParams]="newActionInputParam" (saActionEvent)="saApprovalAction($event)"
            (mmdActionEvent)="mmdApprovalAction($event)" (saBulkActionEvent)="saBulkApprovalAction($event)"
            (mmdBulkActionEvent)="mmdBulkApprovalAction($event)" [dummyInput]="dummyInput"
            [currentActionName]="actionName"
            *ngIf="newActionInputParam.changeType==='Change Data'"></app-change-data-approval>
    </div>
    <div *ngIf="newActionInputParam.changeType==='Extension MRP Update'">
        <app-extn-mrp-update-approval [requiredParams]="newActionInputParam" (saActionEvent)="saApprovalAction($event)"
            (mmdActionEvent)="mmdApprovalAction($event)" (saBulkActionEvent)="saBulkApprovalAction($event)"
            (mmdBulkActionEvent)="mmdBulkApprovalAction($event)" [dummyInput]="dummyInput"
            [currentActionName]="actionName"
            *ngIf="newActionInputParam.changeType==='Extension MRP Update'"></app-extn-mrp-update-approval>
    </div>
</div>