
export const ChangeDataConst = {
    //BUApiName: 'api/ChangeData',
    //BUPath: 'BU',
    ApiName: 'api/RequestLine',
    getChangeTypes: '/GetChangeTypes',
    getChangeDataInfo: '/GetChangeDataInfo',
    getChangeDataInfoForAbu: '/GetChangeDataInfoForAbu',
    createChangeDataAction: '/CreateChangeDataAction',
    updateChangeDataAction: '/UpdateChangeDataAction',
    getChangeDataAction: '/GetChangeDataAction',
    importChangeDataAction: '/ImportChangeDataAction',
    getBranachPlants: '/GetBranchPlants',
    getMatNumChangeTypeDetails: '/GetMatNumChangeTypeDetails',
    globalErrorMessage: 'Please review the highlighted fields before proceeding',
    generateExcelErrorMessage: 'Data change type has been already added in the table for the selected material number(s).',
    invalidMaterialNumberEnteredMessage: 'Enter 3 or more characters to search for material number',
    excelvalidations: {
        invalidSARNum: 'Invalid SAR request number',
        invalidSARIssueId: 'Invalid SAR request issue id',
        noRows: 'No rows were found in the Excel spreadsheet',
        invalidSARkey: 'Invalid SAR key',
        invalidMaterialNumber: 'Invalid Material Number',
        invalidChangeType: 'Invalid Change Type',
        duplicateEntry: 'Duplicate entry found',
        otherChangeTypeValidation: 'Enter Other Type value',
        invalidPlant: 'Invalid Plant',
        enterOldDataValue: 'Enter Old Data value',
        enterNewDataValue: 'Enter New Data value',
        enterNumericNewDataValue: 'Enter numeric New Data value',
        invalidOldDataValue: 'Invalid Old Data value',
        enterValidLeadTimeValue: 'Enter valid lead time value',
        successfulUploadMessage: 'Row added/updated successfully',
    }
};

export const ChangeDataEnum = {
    materialGroupCode: 1,
    manufacturerPartNumber: 2,
    unitOfMeasure: 3,
    leadTime: 4,
    other: 5,
    qualityCode: 6,
    description: 7,
    manufacturer: 8,
    extendBranchPlant: 9,
    vendorPartNumber: 10,
    plannerNumber: 11,
    stockingType: 12,  
    supplierNumber: 13,
}
interface ChangeDataEnumObj {
    [key: string]: any;
}
export const ChangeDataEnumObj: ChangeDataEnumObj = {
    materialGroupCode: 1,
    manufacturerPartNumber: 2,
    unitOfMeasure: 3,
    leadTime: 4,
    other: 5,
    qualityCode: 6,
    description: 7,
    manufacturer: 8
}
export const ChangeDataOperationConst = {
    create: 1,
    update: 2,
    delete: 3
}
